<template>
  <div class="home">

    <div class="row ">
      <div class="col-md-12 text-center text-muted">
        <h4 class="font-weight-semibold title_color">Request Cards</h4>
      </div>
    </div>

    <section class="wrapper">
      <div class="container-fostrap">

        <div class="content">
          <div class="container">

            <div class="row" style="height: 400px;"  v-if="!newuser_card" >

              <div class="col-xs-12 col-sm-4" v-if="userinfo_card">

                <div class="card">
                  <div class="header" style="background-color: whitesmoke;">
                    <h4 class="card-title">
                      User Info
                    </h4>
                  </div>
                  <div class="card-content">
                    <form role="form">

                      <div class="form-group row">
                        <label class="col-md-5 col-form-label font-weight-semibold">Emp Code:</label>
                        <div class="col-md-7">
                          <input class="form-control" v-model="profile.usercode" readonly />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="empname" class="font-weight-semibold">
                          Emp Name
                        </label>
                        <input id="empname" class="form-control" v-model="profile.username" readonly/>
                      </div>

                    </form>
                  </div>
                  <div class="card-read-more">
                    <p/>
                    <div class="row" style="padding-left: 10px; padding-right: 10px;">
                      <div v-if="profile.status == 'D'" class="col btn"
                           style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(1)">Enable
                      </div>
                      <div v-if="profile.status == 'E'" class="col btn"
                           style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(2)" >Disable
                      </div>
                      <div v-if="profile.loginallowed" class="col btn"
                           style="background-color: #7a7a7a;border-radius: 0px;"  @click="showModal(3)">Logout
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div class="col-xs-12 col-sm-4" v-if="branchinfo_card" >
                <div class="card">
                  <div class="header">
                    <h4 class="card-title title_color">
                      Branch Info
                    </h4>
                  </div>
                  <div class="card-content">

                    <div class="form-group row">
                      <label class="col-md-5 col-form-label font-weight-semibold">Home Branch:</label>
                      <div class="col-md-7">
                        <input class="form-control" v-model="profile.homebrn" readonly/>
                      </div>
                    </div>

                    <div class="form-group row" v-if="profile.homebrn != profile.workingbrn">
                      <label class="col-md-5 col-form-label font-weight-semibold">Working Branch:</label>
                      <div class="col-md-7">
                        <input class="form-control" v-model="profile.workingbrn" readonly/>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="font-weight-semibold">To Branch</label>
                      <select id="cmbbranch" class="form-control" v-if="fcrrequest" v-model="fcrrequest.to_ibr">
                        <option v-for="brn in branches" v-bind:value="brn.ibr">
                          {{ brn.ibr }} - {{brn.name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group row"  v-if="profile.homebrn == profile.workingbrn">
                      <label class="col-md-5 col-form-label font-weight-semibold">Upto:</label>
                      <div class="col-md-7">
                        <input type="date" class="form-control" :min="currentDate" :max="maxDate" v-model="uptodate"/>
                      </div>
                    </div>

                  </div>
                  <div class="card-read-more">
                    <p/>
                    <div class="row" style="padding-left: 10px; padding-right: 10px;">
                      <div class="col btn"
                           style="background-color: whitesmoke;border-radius: 0px;"  @click="showModal(8)">Transfer
                      </div>
                      <div v-if="profile.homeibr != profile.workingibr" class="col btn"
                           style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(9)">Return To Home
                      </div>
                      <div v-if="profile.homeibr == profile.workingibr" class="col btn" style="background-color: #7a7a7a;border-radius: 0px;" @click="showModal(7)">
                        Deputation
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-4" v-if="levelchange_card">
                <div class="card">
                  <div class="header" style="background-color: whitesmoke;">
                    <h4 class="card-title">
                      Level Change
                    </h4>
                  </div>
                  <div class="card-content">
                    <form role="form">

                      <div class="form-group">
                        <label for="currentlevel">
                          Current Level
                        </label>
                        <input id="currentlevel" class="form-control" v-model="profile.currentlevel" readonly/>
                      </div>

                      <div class="form-group">
                        <label for="reqlevel">
                          Required Level
                        </label>

                        <select id="reqlevel" class="form-control" v-model="requiredTemplate" v-on:change="reqlevel_changed"   >
                          <option value="38">38</option>
                          <option value="48">48</option>
                        </select>

                      </div>

                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Upto:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control" :min="currentDate" :max="maxDate" v-model="currentDate"/>
                        </div>
                      </div>

                      <div v-if="is_officer_with_teller">
                        <div class="form-group">
                          <label for="cbReason">
                            Reason
                          </label>

                          <select id="cbReason" class="form-control" >
                            <option value="1" selected>Clerk on Long Leave due to Medical Grounds</option>
                            <option value="2" >Maternity/Paternity Leave</option>
                            <option value="3" >Deputation/Training for longer duration</option>
                          </select>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">Reason:</label>
                          <div class="col-md-9">
                            <input type="text" class="form-control" v-model="narration"/>
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="cbBsse">
                            BSSE
                          </label>

                          <select id="cbBsse" class="form-control" >
                            <option value="0" selected>No</option>
                            <option value="1" >Yes</option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="cbBsseCount">
                            BSSE Count
                          </label>

                          <select id="cbBsseCount" class="form-control" >
                            <option value="1" selected>1</option>
                            <option value="2" >2</option>
                            <option value="3" >3</option>
                            <option value="4" >4</option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="txtExigencies">
                            BSSEs for exigencies
                          </label>

                          <textarea id="txtExigencies" class="form-control"   rows="3" v-model="exigencies" >
                                              </textarea>
                        </div>
                      </div>


                    </form>
                  </div>
                  <div class="card-read-more">
                    <p/>
                    <div class="row" style="padding-left: 10px; padding-right: 10px;">
                      <div class="col btn" style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(5)">Submit
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-xs-12 col-sm-4" v-if="limitenhancement_card">

                <div class="card">
                  <div class="header" style="background-color: whitesmoke;">
                    <h4 class="card-title">
                      Limit Enhancement
                    </h4>
                  </div>
                  <div class="card-content">
                    <form role="form">

                      <div class="form-group">
                        <label for="currentlimit">
                          Current Limit
                        </label>
                        <input id="currentlimit" class="form-control" v-model="profile.currentlimit" readonly/>
                      </div>

                      <div class="form-group">
                        <label for="reqlimit">
                          Required Limit
                        </label>
                        <select id="reqlimit" class="form-control" v-model="requiredTemplate"   >
                          <option v-for="lvl in alllimits" v-bind:value="lvl.id">
                            {{ lvl.id }} - {{lvl.name }}
                          </option>
                        </select>
                      </div>


                    </form>
                  </div>
                  <div class="card-read-more">
                    <p/>
                    <div class="row" style="padding-left: 10px; padding-right: 10px;">
                      <div class="col btn" style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(11)">Submit
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-4" v-if="forcepassing_card" >
                <div class="card">
                  <div class="header" style="background-color: whitesmoke;">
                    <h4 class="card-title">
                      Force Passing
                    </h4>
                  </div>
                  <div class="card-content">
                    <form role="form">

                      <div class="form-group">
                        <label for="currentlevel1">
                          Current Level
                        </label>
                        <input id="currentlevel1" class="form-control" v-model="profile.currentlevel" readonly/>
                      </div>

                      <div class="form-group">
                        <label for="requiredforcepasslimit1">
                          Required Level
                        </label>
                        <select id="requiredforcepasslimit1" class="form-control" v-model="requiredTemplate"   >
                          <option v-for="lvl in allforcepasslimits" v-bind:value="lvl.id">
                            {{ lvl.id }} - {{lvl.name }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Upto:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control" :min="currentDate" :max="maxDate" v-model="fcrrequest.date"/>
                        </div>
                      </div>


                    </form>
                  </div>
                  <div class="card-read-more">
                    <p/>
                    <div class="row" style="padding-left: 10px; padding-right: 10px;">
                      <div class="col btn" style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(10)">Submit
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-4" v-if="newuser_card">
                <div class="card">
                  <div class="header" style="background-color: whitesmoke;">
                    <h4 class="card-title">
                      New User
                    </h4>
                  </div>
                  <div class="card-content">
                    <form role="form">

                      <div class="form-group row">
                        <label class="col-md-5 col-form-label font-weight-semibold">Emp Code:</label>
                        <div class="col-md-7">
                          <input class="form-control" v-model="profile.usercode" readonly />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="empname" class="font-weight-semibold">
                          Emp Name
                        </label>
                        <input class="form-control" v-model="profile.username" readonly/>
                      </div>

                    </form>
                  </div>
                  <div class="card-read-more">
                    <p/>
                    <div class="row" style="padding-left: 10px; padding-right: 10px;">
                      <div class="col btn"
                           style="background-color: whitesmoke;border-radius: 0px;" @click="showModal(6)"> Submit
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <vmodal name="confirmation-window" transition="nice-modal-fade" :delay="100" :width="400" :height="200"   >
      <ConfirmationBox v-bind:myrequesttype="requesttype" v-bind:myremarks="remarks" v-on:cofirmation_no="closeModal"  v-on:cofirmation_yes = "getremarks" ></ConfirmationBox>
    </vmodal>

  </div>
</template>

<script>
export default {
  name: 'demo',
  mounted () {
    const self = this;
    self.$data.currentuser.nature = 'BRN';
  },
  data () {
    return {
      remarks:'',
      mindate :'0001-01-01',
      uptodate:'0001-01-01',
      currentuser: {},
      branches: [],
      branch: JSON.parse('{"id":0,"ibr":0,"limit":0,"from":"0001-01-01","upto":"0001-01-01"}'),
      profile: JSON.parse('{"usercode":"","username":"","status":"","lastlogin":"", "loginallowed":"N", "designation":"","grade":"","rank":"","department":"","homeibr":"","homebrn":"","workingibr":"","workingbrn":"","currentlimit":"","currentlevel":""}'),
      fcrrequest: JSON.parse('{"id": 0, "module": 1, "type": 0, "user_code": "", "mode": 1, "category": 1, "hr_ibr": "0", "to_ibr": "0", "date": "0001-01-01", "upto": "0001-01-01", "remarks": ""}'),
      requesttype:0,
      alllevels: [],
      alllimits: [],
      allforcepasslimits: [],
      requiredTemplate: 1,
      userinfo_card : false,
      branchinfo_card : false,
      levelchange_card : true,
      limitenhancement_card : false,
      forcepassing_card : false,
      newuser_card : false,
      officer_level_change_card: false,
      exigencies:'',
      narration:'',
      is_officer_with_teller: false,
    }
  },
  methods:{
    reqlevel_changed(value){
      const self = this;
      const str = String(value);


      self.$data.is_officer_with_teller = false;

      switch (self.$data.currentuser.cadre) {
        case 'E':
          self.$data.is_officer_with_teller = false;
          break;
        case 'O':
          if( self.$data.currentuser.nature == 'BRN' && (self.$data.requiredTemplate == 38 || self.$data.requiredTemplate == 48 ))
            self.$data.is_officer_with_teller = true;
          else
            self.$data.is_officer_with_teller = false;
          alert(self.$data.is_officer_with_teller);
          break;
        case 'Z':
          self.$data.is_officer_with_teller = false;
          break;
        case 'C':
          self.$data.is_officer_with_teller = false;
          break;
        case 'B':
          self.$data.is_officer_with_teller = false;
          break;
      }

      alert(self.$data.currentuser.cadre + " --- " + self.$data.is_officer_with_teller);

    },
  },
}
</script>

<style scoped>

</style>
